<template>
  <PromiseContainer :promises.sync="promises">
    <template v-slot:default>
      <PageLinkHeader :router-link="userCalendarPage" :link-text="'Terug naar Dagoverzicht van ' + getUserName" :header="'Details pricking van ' + getUserName"/>
      <ClockingDetailView :date="date" :clockingId="clockingId" />
    </template>
  </PromiseContainer>
</template>

<script>
import RouteNames from "@/router/RouteNames";
import PageLinkHeader from "@/components/shared/PageLinkHeader";
import PromiseContainer from "@/components/shared/PromiseContainer.vue";
import ClockingDetailView from "./ClockingDetailView"

export default {
  name: "UserClockingDetailView",
  components: {
    PageLinkHeader,
    PromiseContainer,
    ClockingDetailView
  },
  props: {
    userId: {
      type: String,
      required: true
    },
    date: {
      type: String,
      required: true
    },
    returnRoute: {
      type: Object,
      required: false,
      default:() => ({name: RouteNames.MODIFY_USER_HOURS_DAY}),
    },
    clockingId: {
      default: null,
      required: true,
      type: String
    },
  },
  data() {
    return {
      userName: {},
      userCalendarPage: {name: this.returnRoute.name, params: {userId: this.userId, date: this.date}},
      promises: [
        this.$store.dispatch('usersModule/fetchUserName', this.userId)
        .then(data => this.userName = data)
      ]
    }
  },
  computed: {
    getUserName(){
      if (!this.userName?.firstName || !this.userName?.lastName) {
        return ''
      }
      return `${this.userName.firstName} ${this.userName.lastName}`
    }
  },
}
</script>

